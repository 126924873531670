
import { defineComponent } from 'vue'
import ByocDetailsPage from '@/components/pages/chats/settings/byoc/byocDetails/ByocDetailsPage.vue'
import ByocDetailsVonageGeneral from '@/components/pages/chats/settings/byoc/byocDetails/vonage/ByocDetailsVonageGeneral.vue'
import ByocDetailsLinkedNumbers from '@/components/pages/chats/settings/byoc/byocDetails/ByocDetailsLinkedNumbers.vue'
import { providerVonage } from '@/definitions/chats/settings/byoc/data'

export default defineComponent({
  components: {
    ByocDetailsPage,
    ByocDetailsVonageGeneral,
    ByocDetailsLinkedNumbers,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Bring your own CPaaS', url: { name: 'base.chats.settings.byoc' } },
      { label: 'Vonage' },
    ]

    return {
      breadcrumbs,
      provider: providerVonage,
    }
  },
})
